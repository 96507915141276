/* eslint-disable @typescript-eslint/no-explicit-any */
import { Language, LanguageIndexed } from './language.model';

export const toLanguage = (record: any): Language => ({
    code: record.code,
    description: record.description,
});

export const toLanguages = (record: any): Language[] =>
    record.languages.map(toLanguage);

export const toLanguageIndexed = (record: any | Language): LanguageIndexed => ({
    ...toLanguage(record),
    id: record.code,
});
