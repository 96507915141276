import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { BehaviorSubject, Observable, ReplaySubject, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppSettings, defaultSettings } from '../models/app-settings.model';
import { CustomerLogo } from '../models/customer-logo.model';
import { toTimezone } from '../models/date-time/date-time.deserialize';
import { Timezone } from '../models/date-time/date-time.model';
import {
    Domain,
    EnvironmentInfo,
} from '../models/environment-info/environment-info.model';
import { Language } from '../models/language/language.model';
import { ENVIRONMENT } from '../providers/environment.provider';
import { LANGUAGE_CONFIG } from '../providers/language.provider';
import { EnvironmentInfoService } from './environment-info.service';
import { QuillI18nService } from './quill-i18n.service';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    private readonly baseUrlSettings = `${inject(ENVIRONMENT).apiBasePath.common}/core/settings`;
    private readonly cookieCurrentLangKey = 'interacta2:language';
    private readonly defaults: AppSettings = defaultSettings;
    private readonly availableLanguages: Language[] | null =
        inject(LANGUAGE_CONFIG);
    private languages: Language[] = [];
    timezones = signal<Timezone[]>([]);
    private environmentInfo$ = new BehaviorSubject<EnvironmentInfo | undefined>(
        undefined,
    );
    private currentLanguage$ = new BehaviorSubject<Language>(
        this.defaults.language,
    );
    private customerDomains: Domain[] = [];
    private updateVersionRequired$ = new BehaviorSubject<boolean>(false);
    private localeDateFns = new ReplaySubject<Locale>(1);

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private environmentInfoService: EnvironmentInfoService,
        private dateFnsConf: DateFnsConfigurationService,
        private dateAdapter: DateAdapter<Date>,
        private quillI18nService: QuillI18nService,
    ) {}

    getDefaultInfo(): AppSettings {
        return this.defaults;
    }

    getCurrentLanguageStream(): Observable<Language> {
        return this.currentLanguage$.asObservable();
    }

    getCurrentLanguage(): Language {
        return this.currentLanguage$.value;
    }

    getLanguage(code: string): Language | null {
        if (this.languages)
            return this.languages.find((lang) => lang.code === code) ?? null;
        return null;
    }

    getAppLanguages(): Language[] {
        return this.languages;
    }

    getCustomerDomains(): Domain[] {
        return this.customerDomains;
    }

    /**
     *
     * @deprecated use {@link timezones} signal instead: `timezones()`.
     */
    getTimezones(): Timezone[] {
        return this.timezones();
    }

    getTimezone(zoneId: string | null): Timezone {
        let timezone: Timezone | undefined = undefined;
        if (zoneId) {
            timezone = this.timezones().find((t) => t.zoneId === zoneId);
        }
        return timezone || this.defaults.timezone;
    }

    getTimezoneById(id: number | null): Timezone | null {
        if (id) {
            return this.timezones().find((t) => t.id === id) ?? null;
        }
        return null;
    }

    getEnvironmentInfoStream(): Observable<EnvironmentInfo | undefined> {
        return this.environmentInfo$.asObservable();
    }

    getEnvironmentInfo(): EnvironmentInfo | undefined {
        return this.environmentInfo$.value;
    }

    setUpdateVersionRequired(): void {
        this.updateVersionRequired$.next(true);
    }
    checkUpdateVersionRequired(): Observable<boolean> {
        return this.updateVersionRequired$;
    }

    updateEnvironmentInfo(): Observable<EnvironmentInfo> {
        return this.environmentInfoService.getEnvironmentInfo().pipe(
            tap((environmentInfo) => {
                // log build-info

                const color = 'color: #ff395a';
                console.group(`%c${environmentInfo.env} ENVIRONMENT`, color);
                console.group('%cBACKEND', color);
                console.log(
                    `%cversion: ${environmentInfo.buildInfo.backend.version}`,
                    color,
                );
                console.log(
                    `%cbuild time: ${
                        environmentInfo.buildInfo.backend.buildTime?.toISOString() ??
                        ''
                    }`,
                    color,
                );
                console.log(
                    `%chash: ${environmentInfo.buildInfo.backend.hash}`,
                    color,
                );
                console.groupEnd();
                console.group('%cFRONTEND', color);
                console.log(
                    `%cversion: ${environmentInfo.buildInfo.frontend.version}`,
                    color,
                );
                console.log(
                    `%cbuild time: ${
                        environmentInfo.buildInfo.frontend.buildTime?.toISOString() ??
                        ''
                    }`,
                    color,
                );
                console.log(
                    `%chash: ${environmentInfo.buildInfo.frontend.hash}`,
                    color,
                );
                console.groupEnd();
                console.groupEnd();
            }),
            map((environmentInfo) => {
                // initialize languages
                const languages = environmentInfo.languages.filter(
                    (lang) =>
                        this.availableLanguages?.some(
                            (filteredLang) => filteredLang.code === lang.code,
                        ) ?? true,
                );

                const defaultLanguage = this.computeDefaultLanguage(
                    languages,
                    environmentInfo.systemDefaultLanguage ??
                        defaultSettings.language,
                );
                this.initTranslateLang(languages, defaultLanguage);
                this.defaults.systemLanguage =
                    environmentInfo.systemDefaultLanguage ??
                    defaultSettings.language;

                // initalize customer domanins

                this.customerDomains = environmentInfo.plantSupportedDomains;

                // initalize enviroment-info

                this.environmentInfo$.next(environmentInfo);

                return environmentInfo;
            }),
        );
    }

    getLocaleDateFns(): Observable<Locale> {
        return this.localeDateFns.asObservable();
    }

    updateTimezones(): Observable<Timezone[]> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<any>(`${this.baseUrlSettings}/timezones`).pipe(
            map((result) => {
                if (result.systemDefaultTimezone) {
                    this.defaults.timezone = toTimezone(
                        result.systemDefaultTimezone,
                    );
                }
                const timezones: Timezone[] = result.timezones
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (<Array<any>>result.timezones).map(toTimezone)
                    : [];
                this.timezones.set(timezones);

                return timezones;
            }),
        );
    }

    initTranslateLang(
        languages: Language[] = [this.defaults.language],
        defaultLanguage: Language = this.defaults.language,
    ): void {
        this.defaults.language = defaultLanguage;
        this.configureLanguages(languages);
    }

    getCustomerLogoUrl(): CustomerLogo {
        const baseUrl = `${
            this.getEnvironmentInfo()?.plantAssetsBaseUrl ?? ''
        }/webapp/images`;

        return {
            light: `${baseUrl}/logo_customer.png`,
            dark: `${baseUrl}/logo_customer_dark.png`,
        };
    }

    /*
     * only if the api call "/current-environment" successfully done
     */
    private computeDefaultLanguage(
        availableLanguages: Language[],
        systemDefaultLanguage: Language,
    ): Language {
        const lastLanguageJson = localStorage.getItem(
            this.cookieCurrentLangKey,
        );

        const lastUsedLanguage: Language | null = lastLanguageJson
            ? JSON.parse(lastLanguageJson)
            : null;

        let defaultLanguage: Language | null = null;

        // Use last selected language from local storage (if any available)
        if (lastUsedLanguage) {
            defaultLanguage = availableLanguages
                ? availableLanguages.find(
                      (l) => l.code === lastUsedLanguage.code,
                  ) ?? null
                : null;
        }

        // Otherwise use browser language (if any available)
        if (!defaultLanguage) {
            const clientLanguageCode =
                navigator.language && navigator.language.length >= 2
                    ? navigator.language.slice(0, 2).toLowerCase()
                    : null;

            if (clientLanguageCode) {
                defaultLanguage = availableLanguages
                    ? availableLanguages.find(
                          (l) => l.code === clientLanguageCode,
                      ) ?? null
                    : null;
            }
        }

        // Otherwise use system default language
        if (!defaultLanguage) {
            defaultLanguage = systemDefaultLanguage;
        }

        // Otherwise use hardcoded default
        if (!defaultLanguage) {
            defaultLanguage = defaultSettings.language;
        }

        return defaultLanguage;
    }

    private setCurrentLanguage(currentLang: Language) {
        this.currentLanguage$.next(currentLang);
    }

    private getDateFnsLocale(code: string): Observable<Locale> {
        const getLocale = () => {
            switch (code) {
                case 'de':
                    return import('date-fns/locale/de');
                case 'es':
                    return import('date-fns/locale/es');
                case 'it':
                    return import(`date-fns/locale/it`);
                case 'el':
                    return import(`date-fns/locale/el`);
                case 'pt':
                    return import(`date-fns/locale/pt`);
                case 'fr':
                    return import(`date-fns/locale/fr`);
                default:
                    return import('date-fns/locale/en-GB');
            }
        };
        return from(getLocale()).pipe(map((res) => res.default));
    }

    /*
     * Configuration of translate service lib
     * - add list of languages
     * - set default lang
     * - use it
     * - subscribe on changes this value
     */
    private configureLanguages(languages: Language[]) {
        const translateLanguages = languages.map((lang: Language) => lang.code);
        this.translate.addLangs(translateLanguages);
        this.languages = languages;

        this.translate.setDefaultLang(this.defaults.language.code);
        this.translate.use(this.defaults.language.code);
        this.setCurrentLanguage(this.defaults.language);

        this.translate.onLangChange
            .pipe(
                map(
                    (event) =>
                        languages.find(
                            (lang) =>
                                lang.code.toUpperCase() ===
                                event.lang.toUpperCase(),
                        ) || this.defaults.language,
                ),
                switchMap((lang) =>
                    this.getDateFnsLocale(lang.code).pipe(
                        map((dateFnsLocale) => ({ lang, dateFnsLocale })),
                    ),
                ),
            )
            .subscribe(({ lang, dateFnsLocale }) => {
                localStorage.setItem(
                    this.cookieCurrentLangKey,
                    JSON.stringify(lang),
                );

                this.localeDateFns.next(dateFnsLocale);
                this.dateAdapter.setLocale(dateFnsLocale);
                this.dateFnsConf.setLocale(dateFnsLocale);
                this.quillI18nService.applyCurrentLanguage();

                this.setCurrentLanguage(lang);
            });
    }
}
