import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { CustomError } from '@interacta-shared/data-access-error';
import { LoadingLayerService } from '@interacta-shared/util-common';
import {
    BehaviorSubject,
    catchError,
    concatMap,
    defer,
    distinctUntilChanged,
    exhaustMap,
    filter,
    finalize,
    iif,
    map,
    Observable,
    of,
    retry,
    switchMap,
    tap,
    throwError,
    timer,
} from 'rxjs';
import { toCurrentUser } from '../model/auth-user/auth-user.deserialize';
import { CurrentUser } from '../model/auth-user/auth-user.model';
import { SessionState } from '../model/session-state.model';
import { UserLoginError } from '../model/user-login-error.model';
import { RefreshTokenService } from './refresh-token.service';

interface SessionData {
    sessionAccessToken: string | null;
    sessionExpired: boolean;
    userData: CurrentUser | null;
}

interface CreateAccessTokenResponse {
    accessToken: string;
    expiresInSeconds: number;
}

const RENEWAL_RETRY_INTERVAL_IN_SECONDS = 60;

@Injectable({ providedIn: 'root' })
export class AuthService {
    sessionData$ = new BehaviorSubject<SessionData>({
        sessionAccessToken: null,
        sessionExpired: false,
        userData: null,
    });
    sessionState$: Observable<SessionState> = this.sessionData$.pipe(
        map((data) => ({
            signedIn: data.sessionAccessToken != null && data.userData != null,
            sessionExpired: data.sessionExpired,
        })),
        distinctUntilChanged(
            (a, b) =>
                a.signedIn === b.signedIn &&
                a.sessionExpired === b.sessionExpired,
        ),
    );
    login$ = this.sessionState$.pipe(filter(({ signedIn }) => signedIn));

    logout$ = this.login$.pipe(
        switchMap(() => this.sessionState$),
        filter(({ signedIn }) => !signedIn),
    );

    currentUserData$: Observable<CurrentUser | null> = this.sessionData$.pipe(
        map((data) => data.userData),
    );

    currentUserData = toSignal(this.currentUserData$, { requireSync: true });

    private readonly commonApiBasePath = inject(ENVIRONMENT).apiBasePath.common;
    private readonly baseUrl = `${this.commonApiBasePath}/core/auth`;
    private readonly refreshTokenService = inject(RefreshTokenService);
    private readonly http = inject(HttpClient);
    private readonly configurationService = inject(ConfigurationService);
    private readonly loadingLayerService = inject(LoadingLayerService);

    constructor() {
        this.applyRefreshTokenEarlyRenewPolicy();
    }

    /**
     * @deprecated use {@link currentUserData} signal instead: `currentUserData()`.
     */
    getCurrentUserData(): CurrentUser | null {
        return this.sessionData$.value.userData;
    }

    getCurrentAccessToken(): string | null {
        return this.sessionData$.value.sessionAccessToken;
    }

    fetchCurrentUserData(): Observable<CurrentUser> {
        return this.http
            .get(`${this.baseUrl}/current-user-data`)
            .pipe(map((u) => toCurrentUser(u)));
    }

    signInWithRefreshTokenIfAny(): Observable<void> {
        return this.renewAccessToken().pipe(
            exhaustMap(() => this.doExecuteSignIn()),
        );
    }

    signInWithPreAuthenticatedAccessToken(
        preAuthAccesToken: string,
    ): Observable<void> {
        if (preAuthAccesToken == null) {
            return throwError(
                () =>
                    new Error('Pre authentication passed an empty auth token.'),
            );
        }

        this.updateSessionData({
            sessionAccessToken: preAuthAccesToken,
        });
        return this.doExecuteSignIn();
    }

    signInWithCredentials(
        username: string,
        password: string,
        otpCode?: string,
    ): Observable<void> {
        const requestData = {
            username: username,
            password: password,
            otp: otpCode ?? undefined,
        };
        return this.http
            .post<CreateAccessTokenResponse>(
                `${this.baseUrl}/create-access-token-by-credentials`,
                requestData,
            )
            .pipe(this.handleAccessToken());
    }

    signInWithGoogleOAuth2Credentials(
        googleOAuth2Token: string,
    ): Observable<void> {
        const requestData = {
            googleOAuth2Token: googleOAuth2Token,
        };
        return this.http
            .post<CreateAccessTokenResponse>(
                `${this.baseUrl}/create-access-token-by-google-oauth2-access-token-credentials`,
                requestData,
            )
            .pipe(
                this.handleAccessToken(),
                catchError((error) => this.responseOAuth2SessionError(error)),
            );
    }

    signInWithMicrosoftOAuth2Credentials(
        microsoftOAuth2Token: string,
    ): Observable<void> {
        const requestData = {
            microsoftOAuth2Token: microsoftOAuth2Token,
        };
        return this.http
            .post<CreateAccessTokenResponse>(
                `${this.baseUrl}/create-access-token-by-microsoft-oauth2-access-token-credentials`,
                requestData,
            )
            .pipe(
                this.handleAccessToken(),
                catchError((error) => this.responseOAuth2SessionError(error)),
            );
    }

    buildGoogleOAuth2LoginUrl(redirectUrlOnSuccess: string): string {
        const client_id =
            this.configurationService.getEnvironmentInfo()
                ?.googleCredentialsAuthClientId;

        if (!client_id) {
            throw new Error('googleCredentialsAuthClientId is NOT configured');
        }

        return this.encodeAuthUrl(
            'https://accounts.google.com/o/oauth2/v2/auth',
            {
                redirect_uri: this.buildRedirectUri(
                    'google-oauth2-redirect.html',
                ),
                client_id,
                scope: 'email profile',
                response_type: 'token',
                state: btoa(
                    JSON.stringify({
                        context: 'google_oauth2_callback',
                        redirectUrlOnSuccess: redirectUrlOnSuccess,
                    }),
                ),
                access_type: 'online',
            },
        );
    }

    buildMicrosoftOAuth2LoginUrl(redirectUrlOnSuccess: string): string {
        const client_id =
            this.configurationService.getEnvironmentInfo()
                ?.microsoftCredentialsAuthClientId;

        if (!client_id) {
            throw new Error(
                'microsoftCredentialsAuthClientId is NOT configured',
            );
        }

        return this.encodeAuthUrl(
            'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize',
            {
                redirect_uri: this.buildRedirectUri(
                    'microsoft-oauth2-redirect.html',
                ),
                client_id,
                scope: 'https://graph.microsoft.com/user.read',
                response_type: 'token',
                response_mode: 'fragment',
                state: btoa(
                    JSON.stringify({
                        context: 'microsoft_oauth2_callback',
                        redirectUrlOnSuccess: redirectUrlOnSuccess,
                    }),
                ),
            },
        );
    }

    buildSpidAuthLoginUrl(
        entityId: string,
        redirectUrlOnSuccess: string,
    ): string {
        return this.encodeAuthUrl(
            `${this.commonApiBasePath}/core/auth/spid/session/create`,
            {
                entityId,
                state: btoa(
                    JSON.stringify({
                        redirectUrlOnSuccess: redirectUrlOnSuccess,
                    }),
                ),
            },
        );
    }

    sessionExpired(): Observable<void> {
        return this.logoutUser(true);
    }

    setProfilePhoto(
        photoUrl: CurrentUser['accountPhotoUrl'],
        occToken: CurrentUser['occToken'],
    ): void {
        const currUser = this.sessionData$.value.userData;

        if (!currUser) {
            throw Error(
                'CurrentUser must be present in order to set private profile photo',
            );
        }

        this.updateSessionData({
            userData: { ...currUser, accountPhotoUrl: photoUrl, occToken },
        });
    }

    setPrivateEmail(
        privateEmail: CurrentUser['privateEmail'],
        occToken: number,
    ): void {
        const currUser = this.sessionData$.value.userData;
        if (!currUser) {
            throw Error(
                'CurrentUser must be present in order to set private email',
            );
        }
        const privateEmailVerificationRequired =
            !!this.configurationService.getEnvironmentInfo()
                ?.privateEmailVerificationEnabled && !currUser?.contactEmail;

        this.updateSessionData({
            userData: {
                ...currUser,
                privateEmail,
                privateEmailVerified: false,
                privateEmailVerificationRequired,
                occToken,
            },
        });
    }

    setPrivateEmailVerified(): void {
        const currUser = this.sessionData$.value.userData;

        if (!currUser) {
            throw new Error(
                'The user must be logged in order to set the private email verified',
            );
        }

        this.updateSessionData({
            userData: {
                ...currUser,
                privateEmailVerificationRequired: false,
            },
        });
    }

    signOut(): Observable<void> {
        return this.logoutUser();
    }

    setTermsAcceptTimestamp(): void {
        const currUser = this.sessionData$.value.userData;

        if (!currUser) {
            throw new Error(
                'User must be logged in order to accept terms and conditions',
            );
        }

        this.updateSessionData({
            userData: {
                ...currUser,
                termsAcceptTimestamp: new Date(),
            },
        });
    }

    setEmailNotificationEnabled(
        emailNotificationsEnabled: CurrentUser['emailNotificationsEnabled'],
    ): void {
        const currUser = this.sessionData$.value.userData;
        if (!currUser) {
            throw Error(
                'CurrentUser must be present in order to se private email',
            );
        }
        this.updateSessionData({
            userData: {
                ...currUser,
                emailNotificationsEnabled,
            },
        });
    }

    renewAccessToken(): Observable<string> {
        return this.refreshTokenService
            .renew()
            .pipe(
                tap((sessionAccessToken) =>
                    this.updateSessionData({ sessionAccessToken }),
                ),
            );
    }

    private buildRedirectUri(redirectPage: string): string {
        let redirectUri = window.location.href;
        const authRedirectURIHashPosition = redirectUri.indexOf('#');
        if (authRedirectURIHashPosition > -1) {
            redirectUri = redirectUri.substring(0, authRedirectURIHashPosition);
        }

        return `${redirectUri.substring(
            0,
            redirectUri.lastIndexOf('/'),
        )}/${redirectPage}`;
    }

    private encodeAuthUrl(
        apiUrl: string,
        params: Record<string, string | null>,
    ): string {
        let encodedUrl = encodeURI(apiUrl);
        Object.keys(params).forEach((requestParam, index) => {
            const value = params[requestParam];
            if (value !== null) {
                encodedUrl += index === 0 ? '?' : '&';
                encodedUrl += requestParam + '=' + encodeURIComponent(value);
            }
        });
        return encodedUrl;
    }

    private handleAccessToken() {
        return (
            createAccessToken: Observable<CreateAccessTokenResponse>,
        ): Observable<void> => {
            return createAccessToken.pipe(
                tap((response) => this.responseSessionSuccess(response)),
                switchMap(() => this.doExecuteSignIn()),
            );
        };
    }

    private responseSessionSuccess(response: CreateAccessTokenResponse): void {
        if (!response.accessToken) {
            throw new Error('Backend server returned empty auth token.');
        }
        this.sessionData$.next({
            ...this.sessionData$.value,
            sessionAccessToken: response.accessToken,
        });
        this.refreshTokenService.setNextExpiration(response.expiresInSeconds);
    }

    private updateSessionData(sessionData: Partial<SessionData>): void {
        this.sessionData$.next({
            ...this.sessionData$.value,
            ...sessionData,
        });
    }

    private doExecuteSignIn(): Observable<void> {
        const currentUser$ = this.fetchCurrentUserData().pipe(
            switchMap((user) => {
                if (user.blocked || user.deleted) {
                    return this.logoutUser()
                        .pipe(
                            catchError((e) => {
                                console.warn("Can't revoke refresh token ", e);
                                return of(void 0);
                            }),
                        )
                        .pipe(
                            concatMap(() =>
                                throwError(() =>
                                    user.blocked
                                        ? UserLoginError.Blocked
                                        : UserLoginError.Deleted,
                                ),
                            ),
                        );
                }
                return of(user);
            }),
        );

        return currentUser$.pipe(
            map((currentUser) => ({
                ...currentUser,
                timezone: this.configurationService.getTimezone(
                    currentUser?.timezone?.zoneId ?? null,
                ),
            })),
            tap((currentUser) => {
                this.updateSessionData({
                    userData: currentUser,
                    sessionExpired: false,
                });
            }),
            map(() => void 0),
        );
    }

    private logoutUser(sessionExpired = false): Observable<void> {
        this.loadingLayerService.open({
            firstLabel: 'SHARED.LOGOUT_PENDING',
        });
        return iif(
            () => sessionExpired,
            of(void 0),
            defer(() => this.refreshTokenService.revoke()),
        ).pipe(
            tap(() =>
                this.updateSessionData({
                    sessionAccessToken: null,
                    userData: null,
                    sessionExpired,
                }),
            ),
            finalize(() => this.loadingLayerService.close()),
        );
    }

    private applyRefreshTokenEarlyRenewPolicy() {
        this.refreshTokenService.expirationImpending$.subscribe(() => {
            this.renewAccessToken()
                .pipe(
                    retry({
                        delay: (error) => {
                            if (error.status == 401) {
                                return throwError(() => error);
                            } else {
                                console.warn(
                                    `Access token renewal falied, retry in ${RENEWAL_RETRY_INTERVAL_IN_SECONDS} seconds.`,
                                    error,
                                );
                                return timer(
                                    RENEWAL_RETRY_INTERVAL_IN_SECONDS * 1000,
                                );
                            }
                        },
                        resetOnSuccess: true,
                    }),
                )
                .subscribe({
                    complete: () => console.debug('Access token renewed'),
                    error: () => console.error("Can't renew access token"),
                });
        });
    }

    private responseOAuth2SessionError(error: unknown): Observable<never> {
        if (error instanceof HttpErrorResponse && error.status === 401) {
            error = new CustomError('SHARED.ERROR.INVALID_CREDENTIALS');
        }
        return throwError(() => error);
    }
}
