import { Language } from '../language/language.model';

export interface EnvironmentInfo {
    plantId: number;
    plantBaseUrl: string;
    plantAssetsBaseUrl: string;
    advancedSearch: boolean;
    userCredentialsValidFormat: UserCredentialsValidFormat | null;
    googleCredentialsAuthClientId: string | null;
    microsoftCredentialsAuthClientId: string | null;
    googleApisDefaultApiKey: string | null;
    googleAnalyticsClientId: string | null;
    buildInfo: InteractaBuildInfo;
    env: string;
    installedFeatures: InstalledFeatures;
    privateEmailVerificationEnabled: boolean;
    postExportMaxSize: number;
    postAsyncExportMaxSize: number;
    attachmentMaxSize: number;
    homePageRefreshRate: number;
    languages: Language[];
    systemDefaultLanguage: Language | null;
    plantSupportedDomains: Domain[];
}

export interface InteractaBuildInfo {
    frontend: BuildInfo;
    backend: BuildInfo;
}

export interface BuildInfo {
    buildTime?: Date;
    version: string;
    hash: string;
    loadedPlugins?: InteractaBackendLoadedPlugin[];
}

export interface InstalledFeatures {
    advancedSearch: boolean;
    advancedSearchSections: boolean;
    translate: boolean;
    googleDriveIntegration: boolean;
    socialShare: boolean;
    streamingVideo: boolean;
    toManageProcesses: boolean;
    spidLogin: boolean;
    calculateTotalItemsCount: CalculateItemsCount;
    customSigninUrl: string | null;
    chat: boolean;
    chatEmbedded: boolean;
    ai: boolean;
    aiCommunity: boolean;
    aiContentGenerator: boolean;
    aiGuideCenter: boolean;
    aiPost: boolean;
    aiPostAttachment: boolean;
    xlearning: boolean;
}

export interface InteractaBackendLoadedPlugin {
    id: string;
    description: string;
    version?: unknown;
    moduleClass: string;
}

export enum CalculateItemsCount {
    OFF = 0,
    REQUEST = 1,
    ON = 2,
}

export interface UserCredentialsValidFormat {
    minLength: number;
    maxLength: number;
    atLeastLowerCharsQuantity: number;
    atLeastUpperCharsQuantity: number;
    atLeastNumericCharsQuantity: number;
}

export class Domain {
    public name: string;
    public get label(): string {
        return this.name ? '@' + this.name : this.name;
    }

    constructor(name: string) {
        this.name = name;
    }
}
