/* eslint-disable @angular-eslint/component-selector */
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Appearance, ButtonColor, Palette, Shape, Size } from '../../model';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector:
        'button[interacta-button], div[interacta-button], a[interacta-button]',
    templateUrl: './button.component.html',
    styles: [
        `
            :host {
                display: inline-flex;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '*',
                }),
            ),
            state(
                'close',
                style({
                    width: '0px',
                }),
            ),
            transition('open <=> close', [animate('300ms ease')]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, MatRippleModule, NgClass, IconComponent, LoaderComponent],
})
export class ButtonComponent implements OnChanges {
    @Input() icon?: InteractaIcon;
    @Input() label?: string;
    @Input() width: 'content' | 'full' = 'content';
    @Input() size: Extract<Size, 'large' | 'regular' | 'small'> = 'small';
    @Input() appearance: Appearance = 'fill';
    @Input() bgColor: ButtonColor = 'primary';
    @Input() active = false;
    @Input() shape: Shape = 'rectangular';
    @Input() loading = false;
    @Input() textCenter = false;
    @Input() borderRadius: 'rounded' | 'rounded-left' | 'rounded-right' =
        'rounded';
    @Input() withShadow = false;
    @Input() @HostBinding('disabled') disabled = false;

    // Use following with dark-gray when bg color is not white
    @Input() ghostHoverColor: Extract<Palette, 'surface-B' | 'gray-300'> =
        'surface-B';

    displayLabel?: string;

    constructor(public elementRef: ElementRef<HTMLElement>) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['label']?.currentValue) {
            this.displayLabel = this.label;
        }
    }
}
