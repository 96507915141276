import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    EnvironmentProviders,
    Type,
    makeEnvironmentProviders,
} from '@angular/core';
import { InactiveUserInterceptor } from '../interceptors/inactive-user.interceptor';
import { JwtInterceptor } from '../interceptors/jwt.interceptor';
import { AuthRoutingService } from '../services/auth-routing.abstract-service';

/**
 *
 * @param existing an implementation of AuthRoutingService
 * @param standaloneInterceptors use false in legacy applications importing `HttpClientModule`, true in standalone applications using `provideHttpClient(withInterceptors(authInterceptors))`
 * @returns
 */
export function provideAuth(
    existing: Type<AuthRoutingService>,
    standaloneInterceptors = true,
): EnvironmentProviders {
    const deps = [existing];
    return makeEnvironmentProviders([
        {
            provide: AuthRoutingService,
            useFactory: (existing: AuthRoutingService) => existing,
            deps,
        },
        ...(standaloneInterceptors
            ? [InactiveUserInterceptor, JwtInterceptor]
            : [
                  {
                      provide: HTTP_INTERCEPTORS,
                      useClass: InactiveUserInterceptor,
                      multi: true,
                  },
                  {
                      provide: HTTP_INTERCEPTORS,
                      useClass: JwtInterceptor,
                      multi: true,
                  },
              ]),
    ]);
}
