import {
    EnvironmentProviders,
    InjectionToken,
    makeEnvironmentProviders,
} from '@angular/core';
import { Environment } from '../models/environment.model';

export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT', {
    factory: () => ({
        production: false,
        apiBasePath: { common: './api', learning: '../learning/api' },
    }),
});

export function provideEnvironment(env?: Environment): EnvironmentProviders {
    return makeEnvironmentProviders([{ provide: ENVIRONMENT, useValue: env }]);
}
