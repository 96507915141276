import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '../providers/environment.provider';

@Injectable({ providedIn: 'root' })
export class ConsoleService {
    private readonly environment = inject(ENVIRONMENT);
    debug(production: boolean, message: string, optionalParam?: unknown): void {
        if (production || !this.environment.production) {
            if (optionalParam) {
                console.debug(message, optionalParam);
            } else {
                console.debug(message);
            }
        }
    }

    error(production: boolean, message: string, optionalParam?: unknown): void {
        if (production || !this.environment.production) {
            if (optionalParam) {
                console.error(message, optionalParam);
            } else {
                console.error(message);
            }
        }
    }
    warning(
        production: boolean,
        message: string,
        optionalParam?: unknown,
    ): void {
        if (production || !this.environment.production) {
            if (optionalParam) {
                console.warn(message, optionalParam);
            } else {
                console.warn(message);
            }
        }
    }

    debugDev(message: string, optionalParam?: unknown): void {
        return this.debug(false, message, optionalParam);
    }

    errorDev(message: string, optionalParam?: unknown): void {
        return this.error(false, message, optionalParam);
    }

    warningDev(message: string, optionalParam?: unknown): void {
        return this.warning(false, message, optionalParam);
    }
}
