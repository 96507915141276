import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { interactaIcons } from './icon-registry.model';

@Injectable({
    providedIn: 'root',
})
export class IconPreloaderService {
    constructor(
        private registry: MatIconRegistry,
        private sanitizer: DomSanitizer,
    ) {
        this.preload().catch((error) => {
            console.error('Failed to preload icons');
            throw error;
        });
    }

    private preload(): Promise<void> {
        for (const icon of interactaIcons) {
            this.registry.addSvgIcon(
                icon,
                this.sanitizer.bypassSecurityTrustResourceUrl(
                    `assets/icons/${icon}.svg`,
                ),
            );
        }

        return forkJoin(
            interactaIcons.map((iconName) =>
                this.registry.getNamedSvgIcon(iconName),
            ),
        )
            .pipe(mapTo(void 0))
            .toPromise();
    }
}
