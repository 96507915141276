import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { getRandomNumber } from '@interacta-shared/util';
import { Observable, of, Subject, throwError } from 'rxjs';
import {
    catchError,
    delay,
    map,
    mapTo,
    share,
    switchMap,
    tap,
} from 'rxjs/operators';

const EARLY_RENEWAL_PERCENTAGE = 20;
const MIN_RENEWAL_INTERVAL_IN_SECONDS = 600;
const SUSPEND_RENEWAL = -1;

function randomExtraDelayInSeconds(
    maxSeconds: number,
    stepSeconds: number,
): number {
    const steps = Math.floor(maxSeconds / stepSeconds);
    return getRandomNumber(0, steps) * stepSeconds;
}

interface RenewResponse {
    accessToken: string;
    expiresInSeconds: number;
}

@Injectable({
    providedIn: 'root',
})
export class RefreshTokenService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/core/auth/refresh-token`;

    private _expirationImpending$ = new Subject<number>();

    private _pendingRenewal$: Observable<string> | null = null;

    constructor(private http: HttpClient) {}

    get expirationImpending$(): Observable<void> {
        return this._expirationImpending$.pipe(
            switchMap((expiresInSeconds) => {
                if (expiresInSeconds === SUSPEND_RENEWAL) {
                    return new Subject<void>();
                } else {
                    const earlyRenewalDelay =
                        Math.max(
                            Math.trunc(
                                expiresInSeconds -
                                    (expiresInSeconds *
                                        EARLY_RENEWAL_PERCENTAGE) /
                                        100,
                            ),
                            MIN_RENEWAL_INTERVAL_IN_SECONDS,
                        ) + randomExtraDelayInSeconds(60, 3);
                    console.debug(
                        `New access token will expire in ${expiresInSeconds} seconds, next renewal will take place in ${earlyRenewalDelay} seconds.`,
                    );
                    return of(void 0).pipe(delay(earlyRenewalDelay * 1000));
                }
            }),
        );
    }

    setNextExpiration(expiresInSeconds: number): void {
        this._expirationImpending$.next(expiresInSeconds);
    }

    renew(): Observable<string> {
        if (!this._pendingRenewal$) {
            this._pendingRenewal$ = this.http
                .post<RenewResponse>(`${this.baseUrl}/renew`, {})
                .pipe(
                    tap((response) => {
                        this._expirationImpending$.next(
                            response.expiresInSeconds,
                        );
                        this._pendingRenewal$ = null;
                    }),
                    map((response) => response.accessToken),
                    catchError((error) => {
                        this._pendingRenewal$ = null;
                        return throwError(error);
                    }),
                    share(),
                );
        }
        return this._pendingRenewal$;
    }

    revoke(): Observable<void> {
        return this.http
            .post<void>(`${this.baseUrl}/revoke`, {})
            .pipe(tap(() => this._expirationImpending$.next(SUSPEND_RENEWAL)));
    }

    get pendingRenewal$(): Observable<void> {
        return this._pendingRenewal$
            ? this._pendingRenewal$.pipe(mapTo(void 0))
            : of(void 0);
    }
}
