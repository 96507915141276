<!-- 
    Classes

    * image__container
    * image__img 

    are provided to customize component style from outside and should not be removed.

-->
<div
    class="image__container"
    [class]="classes"
    [ngClass]="{
        'bg-surface-300': !loaded && loading === 'lazy'
    }"
>
    <img
        #img
        class="image__img select-none transition-opacity duration-100 ease-linear"
        referrerpolicy="no-referrer"
        [alt]="alt"
        [attr.loading]="loading"
        [class]="classes"
        [ngClass]="{
            'opacity-0': !loaded,
            'opacity-100': loaded
        }"
        [src]="_src"
        [srcset]="
            _src + (_fallback ? ', ' + _fallback : '') + ', ' + _noImageSrc
        "
        (error)="onError($event)"
        (load)="onLoad(img)"
    />
</div>
