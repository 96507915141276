import {
    Language,
    Timezone,
} from '@interacta-shared/data-access-configuration';
import { Area, BusinessUnit } from '../organization/organization.model';

export enum PrivateEmailVerificationMode {
    LINK = 1,
    OTP = 2,
}

export interface UserSettings {
    viewUserProfiles: boolean;
    reducedProfile: boolean;
    peopleSectionEnabled: boolean;
    editPrivateEmailEnabled: boolean;
}

export enum LicenceType {
    BASE = 1,
    CHAT = 2,
    XLEARNING = 3,
}

export interface Licence {
    id: number;
    type: LicenceType;
}

export interface AuthUser {
    id: number;
    firstName: string;
    lastName: string;
    caption?: string;
    contactEmail: string;
    googleAccountId: string;
    accountPhotoUrl: string;
    privateProfile: boolean;
    deleted?: boolean;
    blocked?: boolean;
    licences: Licence[];

    // transient
    fullName: string;
}

export interface CurrentUser extends AuthUser {
    hasCustomCredentials?: boolean;
    hasGoogleCredentials?: boolean;
    hasMicrosoftCredentials?: boolean;
    canManageCustomCredentials: boolean;
    timezone?: Timezone;
    language?: Language;
    termsAcceptTimestamp?: Date;
    area?: Area;
    businessUnit?: BusinessUnit;
    occToken: number;
    privateEmail: string | null;
    privateEmailVerified: boolean;
    privateEmailVerificationRequired: boolean;
    emailNotificationsEnabled: boolean;
    userSettings?: UserSettings;
}
