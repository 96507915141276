import { Observable } from 'rxjs';

/**
 * Custom RxJS operator.
 * It maps the source observable using the provided function and it filters
 * the result when it is undefined.
 *
 * Inspired by: https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.filter_map
 */
export function filterMap<T1, T2>(fn: (x: T1) => T2 | undefined) {
    return (source$: Observable<T1>): Observable<T2> => {
        return new Observable<T2>((subscriber) => {
            return source$.subscribe(
                (value) => {
                    const res = fn(value);

                    if (res !== undefined) {
                        subscriber.next(res);
                    }
                },
                (err) => subscriber.error(err),
                () => subscriber.complete(),
            );
        });
    };
}
