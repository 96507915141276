<div
    *ngIf="{
        fill: appearance === 'fill',
        ghost: appearance === 'ghost',
        border: appearance === 'border',
        small: size === 'small',
        regular: size === 'regular',
        large: size === 'large',
        wFull: width === 'full',
        primary: bgColor === 'primary',
        secondary: bgColor === 'secondary',
        tertiary: bgColor === 'tertiary',
        surfaceA: bgColor === 'surface-A',
        gray100: bgColor === 'gray-100',
        gray300: bgColor === 'gray-300',
        textColor: bgColor === 'textColor',
        surfaceB: bgColor === 'surface-B',
        pureWhite30: bgColor === 'pure-white-30',
        textCenter: textCenter === true,
        rounded_l: borderRadius === 'rounded-left',
        rounded_r: borderRadius === 'rounded-right',
        rounded: borderRadius === 'rounded',
        ghostHoverSurfaceB: ghostHoverColor === 'surface-B',
        ghostHoverGray300: ghostHoverColor === 'gray-300'
    } as $"
    class="inline-flex h-full select-none items-center align-top"
    matRipple
    [matRippleDisabled]="disabled || loading"
    [ngClass]="{
        rounded: $.rounded,
        'rounded-l': $.rounded_l,
        'rounded-r': $.rounded_r,
        'bg-surface-primary focus:bg-surface-primary-low-contrast hover:bg-surface-primary-low-contrast text-text-on-primary-reverse':
            $.fill && $.primary && !disabled && !loading,
        'bg-surface-A': $.fill && $.surfaceA && !disabled,
        'bg-surface-100': $.fill && (disabled || $.gray100),
        'bg-surface-300': $.fill && $.gray300,
        'bg-surface-B': $.fill && $.surfaceB,
        'bg-transparent-white-30': $.fill && $.pureWhite30,
        'hover:bg-surface-B':
            ($.border || ($.ghost && $.ghostHoverSurfaceB)) && !disabled,
        'hover:bg-surface-300': $.ghost && $.ghostHoverGray300 && !disabled,
        'border-2 border-solid': $.border,
        'border-border-700': $.border && !active && !disabled,
        'border-text': $.border && $.textColor && !disabled && !active,
        'border-border-primary': $.border && active && !disabled,
        'border-border-500': $.border && disabled,
        'text-text-primary': !$.fill && active && !disabled,
        'text-text-mid-contrast':
            ($.ghost || $.border) && !$.textColor && !disabled && !active,
        'text-text':
            ($.ghost || $.border) && $.textColor && !disabled && !active,
        'text-text-disabled': disabled,
        'text-text-on-primary-reverse': $.fill && $.primary,
        'typo-xs-bold h-24 px-6 py-4': $.small,
        'typo-sm-bold h-36 px-12 py-8': $.regular,
        'typo-medium-bold h-48 px-16 py-12': $.large,
        'w-full': $.wFull,
        'rounded-3xl': shape === 'round',
        'min-w-40': shape === 'round' && !label,
        'justify-center': textCenter || (shape === 'round' && !label),
        shadow: withShadow,
        'bg-palette-secondary': $.secondary,
        'bg-palette-tertiary': $.tertiary,
        'text-text-white': $.secondary || $.tertiary
    }"
    [style.transition]="
        'background-color 300ms ease, color 300ms ease, border 300ms ease, border-radius 300ms ease, padding 300ms ease'
    "
>
    <interacta-icon
        *ngIf="icon"
        [icon]="icon"
        [ngClass]="{
            'opacity-0': loading,
            'size-16': $.small,
            'size-20': $.regular,
            'size-24': $.large
        }"
    />

    <div
        class="overflow-hidden text-ellipsis"
        [@openClose]="label && label.length > 0 ? 'open' : 'close'"
        [ngClass]="{ 'opacity-0': loading }"
    >
        <span
            class="whitespace-nowrap"
            [ngClass]="{
                'ml-6': $.small && icon,
                'ml-8': $.regular && icon,
                'ml-12': $.large && icon
            }"
            >{{ displayLabel }}</span
        >
    </div>

    <interacta-loader *ngIf="loading" class="absolute" [size]="size" />
</div>
