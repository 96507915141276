/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { ConfigurationService } from '../../services/configuration.service';
import { Timezone, ZonedDateTime } from './date-time.model';

/**
 * Deserialize a date (without time) from ISO-8601 formatted string, such as "2018-11-30"
 * @param date
 */
export const toDateFromISO8601 = (date: string): Date | null => {
    if (date) {
        if (date.match(/[0-9]{4}-[0-1][0-9]-[0-3][0-9]/g)) {
            const year = Number.parseInt(date.slice(0, 4));
            const month = Number.parseInt(date.slice(5, 7)) - 1;
            const day = Number.parseInt(date.slice(8, 10));
            return new Date(year, month, day);
        } else {
            throw Error(
                `Invalid date string: "${date}". The expected format is "YYYY-MM-DD".`,
            );
        }
    } else {
        return null;
    }
};

/**
 * Deserialize a date-time from ISO-8601 formatted string, such as
 * "1975-08-19T23:15:30.000Z"
 * "1975-08-19T23:15"
 * "1975-08-19T23:15:30"
 * "1975-08-19T23:15:30.12"
 * "1975-08-19T23:15:30.123"
 * @param date
 */
export const toDateTimeFromString = (
    date: string | null | undefined,
): Date | null => {
    const result = date ? parseISO(date) : null;
    if (result !== null && result.toString() === 'Invalid Date') {
        throw Error(`Invalid date string: "${date}".`);
    }
    return result;
};

export const toZonedDatetime = (
    record: any,
    configurationService: ConfigurationService,
): ZonedDateTime => ({
    zonedDatetime: toDateTimeFromString(record.zonedDatetime),
    localDatetime: toDateTimeFromString(record.localDatetime),
    timezone: configurationService.getTimezone(record.timezone),
});

export const toTimezone = (record: any): Timezone => ({
    id: record.id,
    zoneId: record.zoneId,
    description: record.description || record.zoneId,
    formattedZone: formatZone(record.zoneId as string),
    utcOffset: zoneToUTCFormat(record.zoneId as string),
});

function zoneToUTCFormat(zone: string): string {
    const offset = format(new Date(), 'xx', {
        timeZone: zone,
    });
    const sign = offset.slice(0, 1);
    const hours = +offset.slice(1, 3);
    const minutes = +offset.slice(3);
    return `UTC${sign}${hours}${minutes ? ':' + minutes : ''}`;
}

function formatZone(zone: string): string {
    const slash = new RegExp(/\//g);
    const underscore = new RegExp(/_/g);
    return zone.replace(slash, ', ').replace(underscore, ' ');
}
