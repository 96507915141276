/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrentEnvironment } from '../current-environment/current-environment.model';
import { toDateTimeFromString } from '../date-time/date-time.deserialize';
import {
    BuildInfo,
    CalculateItemsCount,
    EnvironmentInfo,
    InstalledFeatures,
    UserCredentialsValidFormat,
} from './environment-info.model';

export const toBackendBuildInfo = (record: any): BuildInfo => ({
    buildTime: getValidDateOrUndefined(record.buildTime),
    loadedPlugins: record.interactaBackendLoadedPlugins,
    hash: record.interactaBackendScmCommitRef,
    version: record.interactaBackendVersion,
});

export const toFrontendBuildInfo = (record: any): BuildInfo => ({
    buildTime: toDateTimeFromString(record.buildTime as string) ?? undefined,
    version: record.version,
    hash: record.hash,
});

export const toInstalledFeaures = (record: any | null): InstalledFeatures => ({
    advancedSearch: !!record?.advancedSearch,
    advancedSearchSections: !!record?.advancedSearchSections,
    translate: !!record?.translate,
    googleDriveIntegration: !!record?.googleDriveIntegration,
    socialShare: !!record?.socialShare,
    streamingVideo: record.streamingVideo ?? false,
    toManageProcesses: record?.toManageWorkflowPosts ?? true,
    spidLogin: record?.spidLogin ?? false,
    calculateTotalItemsCount:
        record?.calculateTotalItemsCountMode ?? CalculateItemsCount.ON,
    customSigninUrl: record?.customSigninUrl,
    chat: record?.chat ?? false,
    chatEmbedded: record?.chatEmbedded ?? false,
    ai: record.ai ?? false,
    aiCommunity: (record.aiCommunity && record.ai) ?? false,
    aiContentGenerator: (record.aiContentGenerator && record.ai) ?? false,
    aiGuideCenter: (record.aiGuideCenter && record.ai) ?? false,
    aiPost: (record.aiPost && record.ai) ?? false,
    aiPostAttachment:
        (record.aiPostAttachment && record.aiPost && record.ai) ?? false,
    xlearning: record.xlearning ?? false,
});

export const toUserCredentialsValidFormat = (
    record: any,
): UserCredentialsValidFormat => ({
    minLength: record.minLength,
    maxLength: record.maxLength,
    atLeastLowerCharsQuantity: record.atLeastLowerCharsQuantity,
    atLeastUpperCharsQuantity: record.atLeastUpperCharsQuantity,
    atLeastNumericCharsQuantity: record.atLeastNumericCharsQuantity,
});

export const toEnvironmentInfo = (
    currentEnvironmentResponse: CurrentEnvironment,
    frontendBuildInfo: BuildInfo,
): EnvironmentInfo => ({
    env: currentEnvironmentResponse.env,
    buildInfo: {
        backend: currentEnvironmentResponse.buildInfo,
        frontend: frontendBuildInfo,
    },
    advancedSearch:
        currentEnvironmentResponse.installedFeatures?.advancedSearch,
    plantId: currentEnvironmentResponse.plantId,
    plantBaseUrl: currentEnvironmentResponse.plantBaseUrl,
    plantAssetsBaseUrl: currentEnvironmentResponse.plantAssetsBaseUrl,
    userCredentialsValidFormat:
        currentEnvironmentResponse.userCredentialsValidFormat,
    googleCredentialsAuthClientId:
        currentEnvironmentResponse.webappInfo?.googleCredentialsAuthClientId ??
        null,
    microsoftCredentialsAuthClientId:
        currentEnvironmentResponse.webappInfo
            ?.microsoftCredentialsAuthClientId ?? null,
    googleApisDefaultApiKey:
        currentEnvironmentResponse.webappInfo?.googleApisDefaultApiKey ?? null,
    googleAnalyticsClientId:
        currentEnvironmentResponse.webappInfo?.googleAnalyticsClientId ?? null,
    installedFeatures: currentEnvironmentResponse.installedFeatures,
    privateEmailVerificationEnabled:
        currentEnvironmentResponse.privateEmailVerificationEnabled,
    postExportMaxSize: currentEnvironmentResponse.postExportMaxSize,
    postAsyncExportMaxSize: currentEnvironmentResponse.postAsyncExportMaxSize,
    attachmentMaxSize: currentEnvironmentResponse.attachmentMaxSize,
    homePageRefreshRate: currentEnvironmentResponse.homePageRefreshRate,
    languages: currentEnvironmentResponse.languages,
    systemDefaultLanguage: currentEnvironmentResponse.systemDefaultLanguage,
    plantSupportedDomains: currentEnvironmentResponse.plantSupportedDomains,
});

function getValidDateOrUndefined(input: any): Date | undefined {
    const inputDate = new Date(input);
    return !isNaN(inputDate.valueOf()) ? inputDate : undefined;
}
