import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ErrorDisplayService } from '@interacta-shared/data-access-error';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionState } from '../model/session-state.model';
import { UserLoginError } from '../model/user-login-error.model';
import { AuthRoutingService } from './auth-routing.abstract-service';
import { AuthService } from './auth.service';

enum SignedStateType {
    LOGGEDOUT = 0,
    LOGGEDIN = 1,
    REDIRECT_ROOT_PAGE = 2,
    REDIRECT_LOGIN_PAGE = 3,
    REDIRECT_TERMS_PAGE = 4,
    REDIRECT_PRIVATE_EMAIL_PAGE = 5,
}

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
    constructor(
        private router: Router,
        private errorDisplayService: ErrorDisplayService,
        private authService: AuthService,
        private authRoutingService: AuthRoutingService,
    ) {
        this.authService.sessionState$.subscribe((sessionState: SessionState) =>
            this.onSessionStateChanged(sessionState),
        );
    }

    authenticated(url: string): Observable<boolean> {
        const flow: SignedStateType = this.isSignedIn(url);
        if (flow === SignedStateType.LOGGEDIN) {
            return of(true);
        }
        if (flow !== SignedStateType.LOGGEDOUT) {
            return of(false);
        }

        // if logged out, try to login with refresh token (if any)
        return this.authService.signInWithRefreshTokenIfAny().pipe(
            map(() => {
                const flow: SignedStateType = this.isSignedIn(url);
                return flow === SignedStateType.LOGGEDIN;
            }),
            catchError((error) => {
                if (
                    error === UserLoginError.Blocked ||
                    error === UserLoginError.Deleted
                ) {
                    this.errorDisplayService.error(
                        error === UserLoginError.Blocked
                            ? 'SHARED.ERROR.BLOCKED_USER'
                            : 'SHARED.ERROR.DELETED_USER',
                    );
                    this.authRoutingService.navigateLoginPage();
                    return of(false);
                }

                const flow: SignedStateType = this.sessionPersistentError(url);
                return of(flow === SignedStateType.LOGGEDIN);
            }),
        );
    }

    private isSignedIn(url: string): SignedStateType {
        const currentUser = this.authService.currentUserData();
        if (currentUser) {
            if (
                currentUser &&
                !currentUser.termsAcceptTimestamp &&
                !this.authRoutingService.isTermsPage(url)
            ) {
                this.authRoutingService.navigateTermsPage();
                return SignedStateType.REDIRECT_TERMS_PAGE;
            }

            if (
                currentUser?.termsAcceptTimestamp &&
                currentUser?.privateEmailVerificationRequired &&
                !this.authRoutingService.isPrivateEmailPage(url)
            ) {
                this.authRoutingService.navigatePrivateEmailPage();
                return SignedStateType.REDIRECT_PRIVATE_EMAIL_PAGE;
            }

            if (this.authRoutingService.isLoginPage(url)) {
                // if already logged id, skip login page and navigate to root
                this.router.navigateByUrl('/');
                return SignedStateType.REDIRECT_ROOT_PAGE;
            }
            return SignedStateType.LOGGEDIN;
        }
        return SignedStateType.LOGGEDOUT;
    }

    private sessionPersistentError(
        url: string,
    ): Extract<
        SignedStateType,
        SignedStateType.LOGGEDIN | SignedStateType.REDIRECT_LOGIN_PAGE
    > {
        if (this.authRoutingService.isLoginPage(url)) {
            // return of(true);
            return SignedStateType.LOGGEDIN;
        } else {
            this.redirectLoginOrForceLogin(url);
            return SignedStateType.REDIRECT_LOGIN_PAGE;
        }
    }

    private redirectLoginOrForceLogin(url: string): void {
        const params =
            this.router.getCurrentNavigation()?.extractedUrl.queryParams ?? {};
        const forceLoginGoogle = params['forceLogin'] === 'google';
        const forceLoginMicrosoft = params['forceLogin'] === 'microsoft';
        if (forceLoginGoogle) {
            window.location.href =
                this.authService.buildGoogleOAuth2LoginUrl(url);
        } else if (forceLoginMicrosoft) {
            window.location.href =
                this.authService.buildMicrosoftOAuth2LoginUrl(url);
        } else {
            const urlWithParams = this.getUrlWithQueryParams(url, params);

            this.authRoutingService.navigateLoginPage({
                queryParams: { returnUrlToken: btoa(urlWithParams) },
            });
        }
    }

    private getUrlWithQueryParams(url: string, params: Params): string {
        let urlWithParams = url;
        Object.entries(params).forEach(
            ([key, value]) =>
                (urlWithParams += `${
                    urlWithParams.indexOf('?') === -1 ? '?' : '&'
                }${key}=${value}`),
        );

        return urlWithParams;
    }

    private onSessionStateChanged(sessionState: SessionState) {
        if (
            !sessionState.signedIn &&
            this.router.navigated &&
            !this.authRoutingService.isLoginPage(this.router.url) &&
            !this.authRoutingService.isExpiredSessionPage(this.router.url) &&
            !this.authRoutingService.isExternalAuthRedirectPage(this.router.url)
        ) {
            if (sessionState.sessionExpired) {
                this.authRoutingService.navigateExpiredSessionPage();
            } else {
                this.authRoutingService.navigateLoginPage();
            }
        }
    }
}
