import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const bundles = new Map([
    ['--quill-link-insert', 'SHARED.QUILL.LINK.INSERT'],
    ['--quill-link-save', 'SHARED.QUILL.LINK.SAVE'],
    ['--quill-link-edit', 'SHARED.QUILL.LINK.EDIT'],
    ['--quill-link-remove', 'SHARED.QUILL.LINK.REMOVE'],
    ['--quill-link-open', 'SHARED.QUILL.LINK.OPEN'],
]);

@Injectable({
    providedIn: 'root',
})
export class QuillI18nService {
    constructor(
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    applyCurrentLanguage(): void {
        bundles.forEach((bundleKey, cssVar) =>
            this.document.documentElement.style.setProperty(
                cssVar,
                `"${this.translateService.instant(bundleKey)}"`,
            ),
        );
    }
}
