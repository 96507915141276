const errorPages = ['pageNotFound', 'forbiddenAccess', 'unauthorized'] as const;

export type ErrorPage = (typeof errorPages)[number];

export const DEAFULT_ERROR_PAGE_ENTITY_TYPE = 'DEFAULT';
export const USER_NOT_FOUND_ERROR_PAGE_ENTITY_TYPE = 'USER_NOT_FOUND';

export interface ErrorPageInfo {
    title: string;
    description: string;
    logout: boolean;
}

export type ErrorPageInfoRegistryConfig = Record<
    ErrorPage,
    Record<string, ErrorPageInfo>
>;

const defaultInfo: ErrorPageInfoRegistryConfig = {
    pageNotFound: {
        [DEAFULT_ERROR_PAGE_ENTITY_TYPE]: {
            title: 'SHARED.ERROR_PAGE.404.TITLE',
            description: 'SHARED.ERROR_PAGE.404.DESCRIPTION',
            logout: false,
        },
    },
    forbiddenAccess: {
        [DEAFULT_ERROR_PAGE_ENTITY_TYPE]: {
            title: 'SHARED.ERROR_PAGE.403.TITLE',
            description: 'SHARED.ERROR_PAGE.403.DESCRIPTION',
            logout: false,
        },
    },
    unauthorized: {
        [USER_NOT_FOUND_ERROR_PAGE_ENTITY_TYPE]: {
            title: 'SHARED.ERROR_PAGE.USER_NOT_FOUND.TITLE',
            description: 'SHARED.ERROR_PAGE.USER_NOT_FOUND.DESCRIPTION',
            logout: true,
        },
    },
};

export class ErrorPageInfoRegistry {
    private readonly info: ErrorPageInfoRegistryConfig;

    constructor(info?: Partial<ErrorPageInfoRegistryConfig>) {
        this.info = info
            ? errorPages.reduce((acc, errorPage) => {
                  if (info[errorPage]) {
                      acc[errorPage] = {
                          ...defaultInfo[errorPage],
                          ...info[errorPage],
                      };
                  } else {
                      acc[errorPage] = defaultInfo[errorPage];
                  }
                  return acc;
              }, defaultInfo)
            : defaultInfo;
    }

    getInfo(errorPage: ErrorPage, entityType?: string): ErrorPageInfo {
        return (
            (entityType ? this.info[errorPage][entityType] : undefined) ??
            this.info[errorPage][DEAFULT_ERROR_PAGE_ENTITY_TYPE]
        );
    }
}
