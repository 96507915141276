import { Area, BusinessUnit, OrganizationItem } from './organization.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toOrganizationItem = (record: any): OrganizationItem => ({
    id: record.id,
    name: record.name,
    externalId: record.externalId,
    deleted: record.deleted,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toBusinessUnit = (record: any): BusinessUnit =>
    toOrganizationItem(record);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toArea = (record: any): Area => toOrganizationItem(record);
