import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    toArea,
    toBusinessUnit,
} from '../model/organization/organization.deserialize';
import { Area, BusinessUnit } from '../model/organization/organization.model';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    private businessUnits: BusinessUnit[] | null = null;
    private areas: Area[] | null = null;
    private baseUrlData = `${inject(ENVIRONMENT).apiBasePath.common}/core/settings`;

    constructor(private http: HttpClient) {}

    public getBusinessUnits(useCache = true): Observable<BusinessUnit[]> {
        if (this.businessUnits && useCache) {
            return of(this.businessUnits);
        }

        return this.http
            .get<{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                businessUnits: any[] | null;
            }>(`${this.baseUrlData}/business-units`)
            .pipe(
                map((res) => {
                    return res.businessUnits
                        ? res.businessUnits.map(toBusinessUnit)
                        : [];
                }),
                tap((businessUnits) => {
                    this.businessUnits = businessUnits;
                }),
            );
    }

    public getAreas(useCache = true): Observable<Area[]> {
        if (this.areas && useCache) {
            return of(this.areas);
        }

        return (
            this.http
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .get<{ areas: any[] | null }>(`${this.baseUrlData}/areas`)
                .pipe(
                    map((res) => {
                        return res.areas ? res.areas.map(toArea) : [];
                    }),
                    tap((result) => {
                        this.areas = result;
                    }),
                )
        );
    }
}
