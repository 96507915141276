export const interactaIcons = [
    'align-horizontal-center',
    'align-horizontal-left',
    'align-horizontal-right',
    'android-logo',
    'apple-logo',
    'apps',
    'arrow-diagonal',
    'arrow-down',
    'arrow-left',
    'arrow-right',
    'arrow-up',
    'at',
    'backspace',
    'ban',
    'bar-chart',
    'bell',
    'bold',
    'book-reader',
    'building',
    'bullet-list',
    'calculator',
    'calendar-check',
    'camera',
    'card-big',
    'card-medium',
    'card-row',
    'chat',
    'check',
    'check-circle-false',
    'check-circle-true',
    'check-circle-tristate-empty',
    'check-circle-tristate-half',
    'check-circle-undeterminate',
    'checkbox-false',
    'checkbox-true',
    'checkbox-undeterminate',
    'chevron-big-down',
    'chevron-big-left',
    'chevron-big-right',
    'chevron-big-up',
    'chevron-small-down',
    'chevron-small-left',
    'chevron-small-right',
    'chevron-small-up',
    'circle-with-arrow',
    'clock',
    'close',
    'close-thin',
    'closed-eye',
    'code',
    'cog',
    'collapse-width',
    'comments',
    'community',
    'copy',
    'crop',
    'crosshair',
    'csv',
    'dash',
    'date',
    'digital-wp',
    'document',
    'dot',
    'double-arrow-up',
    'download',
    'draw',
    'ellipsis-horizontal',
    'ellipsis-vertical',
    'emoticon',
    'encrypted',
    'envelope',
    'equals',
    'exclamation-circle',
    'expand-width',
    'external-link',
    'file-export',
    'file-import',
    'filter',
    'flash-off',
    'flash-on',
    'flip',
    'fullscreen',
    'fullscreen-exit',
    'globe',
    'gif',
    'google',
    'google-drive',
    'google-logo',
    'grab',
    'graduation-cap',
    'half-sun',
    'hamburger',
    'history-clock',
    'home',
    'image',
    'info',
    'interacta',
    'interacta-logo',
    'italic',
    'key',
    'level-one',
    'level-two',
    'level-three',
    'lightbulb',
    'line-chart',
    'line-diagonal',
    'link',
    'loading',
    'map-marker',
    'megaphone',
    'merge',
    'microphone',
    'microsoft',
    'microsoft-logo',
    'minus',
    'mobile',
    'moon',
    'move-down',
    'multiple-choice',
    'new',
    'new-release',
    'numbers',
    'open-eye',
    'ordlist',
    'padlock',
    'padlock-open',
    'paper-plane',
    'paperclip',
    'paragraph',
    'pause',
    'pen-pad',
    'pencil',
    'pencil-big',
    'people',
    'person',
    'person-check-false',
    'person-check-true',
    'person-circle',
    'persons-cog',
    'phone',
    'picture-in-picture',
    'pin',
    'play',
    'play-fill',
    'play-circle',
    'plug',
    'plus',
    'plus-circle',
    'post',
    'print',
    'push-notification',
    'qr-code',
    'qr-code-reader',
    'question-mark',
    'quote',
    'radio-button-false',
    'radio-button-true',
    'rectangle-empty',
    'redo',
    'relations',
    'replied',
    'reply',
    'restore',
    'retry',
    'retweet',
    'rotate-left',
    'rotate-right',
    'save',
    'search-left',
    'search-right',
    'share',
    'share-square',
    'short-text',
    'simple-mosaic',
    'sidebar-right',
    'simple-mosaic-regular',
    'single-choice',
    'smile',
    'sort-a-z',
    'sort-z-a',
    'spid',
    'spid-logo',
    'split',
    'square-empty',
    'square-fill',
    'star-empty',
    'star-fill',
    'star-half',
    'stream',
    'suitcase',
    'sun',
    'switch-camera',
    'table',
    'tag',
    'task',
    'text',
    'text-bg',
    'text-no-bg',
    'text-size',
    'thumb-down',
    'thumb-up',
    'thumb-up-off',
    'toggle',
    'translate',
    'trash-bin',
    'tree-branch',
    'underline',
    'undeterminate-circle',
    'upload',
    'video',
    'view-column',
    'view-horizontal',
    'view-mosaic',
    'view-vertical',
    'volume-off',
    'volume-up',
    'warning',
    'wifi-off',
    'workflow',
    'workspace',
    'zoom-in',
    'zoom-out',
] as const;

export type InteractaIcon = (typeof interactaIcons)[number];
