export type ErrorCloseBehavior = 'duration' | 'manual' | 'forceReload';

export abstract class ErrorDisplayService {
    abstract warn(
        message: string,
        closeBehavior?: ErrorCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void;

    abstract error(
        message: string,
        closeBehavior?: ErrorCloseBehavior,
        translateParams?: Record<string, unknown>,
    ): void;
}
