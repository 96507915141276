import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface LoadingLayerParams {
    firstLabel: string;
    translateParams?: Record<string, unknown>;
    blackBackground?: boolean;
    delay?: number; // Open layer only after {delay} ms
}

export interface LoadingLayerState {
    loading: boolean;
    content: Required<LoadingLayerParams>;
}

@Injectable({
    providedIn: 'root',
})
export class LoadingLayerService {
    private loadingState$ = new BehaviorSubject<LoadingLayerState>({
        loading: false,
        content: {
            firstLabel: '',
            translateParams: {},
            blackBackground: true,
            delay: 0,
        },
    });

    private delayTimeout: ReturnType<typeof setTimeout> | null = null;

    constructor() {
        this.loadingState$.subscribe(({ loading }) => {
            if (loading) {
                document.body.classList.add('interacta-loading-layer--active');
            } else {
                document.body.classList.remove(
                    'interacta-loading-layer--active',
                );
            }
        });
    }

    open({
        firstLabel = '',
        translateParams = {},
        blackBackground = true,
        delay = 0,
    }: LoadingLayerParams): void {
        this.clearDelayTimeout();

        if (delay) {
            this.delayTimeout = setTimeout(() => {
                this.loadingState$.next({
                    loading: true,
                    content: {
                        firstLabel,
                        translateParams,
                        blackBackground,
                        delay,
                    },
                });
                this.delayTimeout = null;
            }, delay);
        } else {
            this.loadingState$.next({
                loading: true,
                content: {
                    firstLabel,
                    translateParams,
                    blackBackground,
                    delay,
                },
            });
        }
    }

    close(): void {
        this.clearDelayTimeout();
        this.loadingState$.next({
            loading: false,
            content: {
                firstLabel: '',
                translateParams: {},
                blackBackground: true,
                delay: 0,
            },
        });
    }

    getState(): Observable<LoadingLayerState> {
        return this.loadingState$.asObservable();
    }

    private clearDelayTimeout(): void {
        if (this.delayTimeout) {
            clearTimeout(this.delayTimeout);
            this.delayTimeout = null;
        }
    }
}
