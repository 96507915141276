export enum ErrorType {
    GENERIC,
    ATTACHMENT,
    MENTION,
    EXCEPTION,
}

export class CustomError {
    constructor(
        public readonly message: string,
        public readonly warning?: boolean,
        public readonly forceReload?: boolean,
        public readonly messageParams?: Record<string, unknown>,
    ) {}
}
