import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '@interacta-shared/ui-icons';
import { Size } from '../../model';

@Component({
    selector: 'interacta-loader',
    templateUrl: './loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, IconComponent],
})
export class LoaderComponent {
    @Input() size: Extract<Size, 'regular' | 'small' | 'large'> = 'regular';
}
