import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ConsoleService } from '@interacta-shared/data-access-configuration';
import { USER_NOT_FOUND_ERROR_PAGE_ENTITY_TYPE } from '@interacta-shared/data-access-error';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RefreshTokenService } from '../services/refresh-token.service';

const EXCLUDED_PATHS = [
    '/refresh-token',
    '/auth/create-access-token-by',
    'assets',
];

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private readonly consoleService = inject(ConsoleService);
    private readonly router = inject(Router);
    constructor(
        private authService: AuthService,
        private refreshTokenService: RefreshTokenService,
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        /*
         * add authorization header with jwt token if available
         */
        let req = request;

        const accessToken = this.authService.getCurrentAccessToken();
        if (
            accessToken &&
            this.isSameOriginUrl(request.url) &&
            !this.isExcludedPath(request.url)
        ) {
            req = this.setAuthHeader(accessToken, request);
        }

        const handle = this.isExcludedPath(req.url)
            ? next.handle(req)
            : this.refreshTokenService.pendingRenewal$.pipe(
                  mergeMap(() => next.handle(req)),
                  catchError((error) => {
                      if (
                          error.status == 401 &&
                          !this.userNotFoundHeader(error)
                      ) {
                          this.consoleService.debugDev(
                              'Unauthorized error: try to renew access token on the fly',
                          );
                          return this.authService.renewAccessToken().pipe(
                              mergeMap((newAccessToken) => {
                                  const retryReq = this.setAuthHeader(
                                      newAccessToken,
                                      request,
                                  );
                                  return next.handle(retryReq);
                              }),
                              catchError(() => throwError(() => error)),
                          );
                      } else {
                          return throwError(() => error);
                      }
                  }),
              );

        return handle.pipe(
            catchError((errorResponse) => {
                if (errorResponse.status == 401) {
                    if (
                        this.invalidAuthTokenHeader(errorResponse) &&
                        this.authService.getCurrentAccessToken()
                    ) {
                        return this.authService
                            .sessionExpired()
                            .pipe(
                                mergeMap(() => throwError(() => errorResponse)),
                            );
                    } else if (this.userNotFoundHeader(errorResponse)) {
                        this.router.navigate(['/error/unauthorized'], {
                            queryParams: {
                                entityType:
                                    USER_NOT_FOUND_ERROR_PAGE_ENTITY_TYPE,
                            },
                            skipLocationChange: true,
                        });
                    }
                }

                return throwError(() => errorResponse);
            }),
        );
    }

    private setAuthHeader(
        accessToken: string | null,
        request: HttpRequest<unknown>,
    ): HttpRequest<unknown> {
        if (accessToken != null) {
            return request.clone({
                setHeaders: { Authorization: `Bearer ${accessToken}` },
            });
        }
        return request;
    }

    private isSameOriginUrl(targetUrl: string) {
        const targetUrlElement = document.createElement('a');
        targetUrlElement.href = targetUrl;
        if (!targetUrlElement.protocol) {
            return this.isIE11(targetUrlElement);
        }
        const targetUrlElementProtocol = targetUrlElement.protocol;
        // var targetUrlElementPort = targetUrlElement.port ? targetUrlElement.port : { 'http:': 80, 'https:': 443 }[targetUrlElementProtocol];

        const sameOriginUrl =
            window.location.protocol == targetUrlElementProtocol &&
            window.location.hostname == targetUrlElement.hostname;
        // window.location.port == targetUrlElementPort;

        return sameOriginUrl;
    }

    private isIE11(targetUrlElement: HTMLAnchorElement) {
        const href = targetUrlElement.href;

        return (
            href.indexOf(window.location.protocol) !== -1 &&
            href.indexOf(window.location.hostname) !== -1 &&
            href.indexOf(window.location.port) !== -1
        );
    }

    private isExcludedPath(url: string) {
        return EXCLUDED_PATHS.some((path) => url.includes(path));
    }

    private invalidAuthTokenHeader(error: unknown): boolean {
        return (
            error instanceof HttpErrorResponse &&
            error.headers.get('WWW-Authenticate') === 'INVALID_AUTH_TOKEN'
        );
    }

    private userNotFoundHeader(error: unknown): boolean {
        return (
            error instanceof HttpErrorResponse &&
            error.headers.get('authorization-info') === 'USER_NOT_FOUND'
        );
    }
}
