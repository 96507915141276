/* eslint-disable @typescript-eslint/no-explicit-any */
import { defaultSettings } from '../app-settings.model';
import { toTimezone } from '../date-time/date-time.deserialize';
import {
    toBackendBuildInfo,
    toInstalledFeaures,
    toUserCredentialsValidFormat,
} from '../environment-info/environment-info.deserialize';
import { Domain } from '../environment-info/environment-info.model';
import { toLanguage } from '../language/language.deserialize';
import { CurrentEnvironment, WebappInfo } from './current-environment.model';

export const toCurrentEnvironment = (record: any): CurrentEnvironment => ({
    env: record.env,
    buildInfo: toBackendBuildInfo(record.buildInfo),
    webappInfo: record.webappInfo ? toWebappInfo(record.webappInfo) : null,
    systemDefaultLanguage: record.systemDefaultLanguage
        ? toLanguage(record.systemDefaultLanguage)
        : null,
    languages: record.languages
        ? (<Array<unknown>>record.languages).map(toLanguage)
        : [],
    systemDefaultTimezone: record.systemDefaultTimezone
        ? toTimezone(record.systemDefaultTimezone)
        : null,
    plantId: record.plantId,
    plantSupportedDomains: record.plantSupportedDomains
        ? (<Array<string>>record.plantSupportedDomains).map(
              (d) => new Domain(d),
          )
        : [],
    plantBaseUrl: record.plantBaseUrl,
    plantAssetsBaseUrl: record.plantAssetsBaseUrl,
    installedFeatures: toInstalledFeaures(record.installedFeatures),
    userCredentialsValidFormat: record.userCredentialsValidFormat
        ? toUserCredentialsValidFormat(record.userCredentialsValidFormat)
        : null,
    privateEmailVerificationEnabled:
        record.privateEmailVerificationEnabled ?? false,
    postExportMaxSize: record.postExportMaxSize,
    postAsyncExportMaxSize: record.postAsyncExportMaxSize,
    attachmentMaxSize:
        record.attachmentMaxSize ?? defaultSettings.attachmentMaxSize,
    homePageRefreshRate:
        record.homePageRefreshRate ?? defaultSettings.homePageRefreshRate,
});

const toWebappInfo = (record: any): WebappInfo => ({
    googleAnalyticsClientId: record.googleAnalyticsClientId,
    googleCredentialsAuthClientId: record.googleCredentialsAuthClientId,
    microsoftCredentialsAuthClientId: record.microsoftCredentialsAuthClientId,
    googleApisDefaultApiKey: record.googleApisDefaultApiKey,
});
