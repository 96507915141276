import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { Language } from '../models/language/language.model';

export const LANGUAGE_CONFIG = new InjectionToken<Language[] | null>(
    'LANGUAGE_CONFIG',
    {
        factory: () => null,
    },
);

export const provideLanguageConfig = (availableLanguages: Language[]) =>
    makeEnvironmentProviders([
        {
            provide: LANGUAGE_CONFIG,
            useValue: availableLanguages,
        },
    ]);
