import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';

export const canMatchAuthGuard: CanMatchFn = (
    _: Route,
    segments: UrlSegment[],
) => {
    return inject(AuthGuardService).authenticated(
        segments.length > 0 ? segments.map((s) => s.path).join('/') : '',
    );
};
