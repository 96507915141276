import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginDetails } from '../model/login-details.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class Auth2FAService {
    private userData: LoginDetails | null = null;
    private targetUrl = '';
    private otpMail: string | null = null;

    protected readonly authService = inject(AuthService);

    saveUserData(data: LoginDetails, targetUrl: string): void {
        this.userData = data;
        this.targetUrl = targetUrl;
    }

    setOtpMail(otpMail: string | null): void {
        this.otpMail = otpMail;
    }

    clearUserData(): void {
        this.userData = null;
        this.targetUrl = '';
        this.otpMail = null;
    }

    getUserCredentials(): LoginDetails | null {
        return this.userData;
    }

    getOnLoginTargetUrl(): string {
        return this.targetUrl;
    }

    getOtpMail(): string | null {
        return this.otpMail;
    }

    signInWith2faCredentials(otpCode?: string): Observable<void> {
        if (this.userData) {
            return this.authService.signInWithCredentials(
                this.userData.username,
                this.userData.password,
                otpCode,
            );
        } else {
            throw new Error('No username or password available');
        }
    }
}
