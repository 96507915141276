<div
    [ngClass]="{
        'p-8': size === 'large',
        'p-6': size === 'regular',
        'p-4': size === 'small'
    }"
>
    <interacta-icon
        class="text-text-primary block animate-spin"
        icon="loading"
        [ngClass]="{
            'h-36 w-36': size === 'large',
            'h-24 w-24': size === 'regular',
            'h-16 w-16': size === 'small'
        }"
    ></interacta-icon>
</div>
