import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { AppLogo } from '../models/app-logo.model';

const defaultConfig: AppLogo = {
    light: 'assets/shared/images/main-logo.svg',
    dark: 'assets/shared/images/white-logo.svg',
};

export const APP_LOGO = new InjectionToken<AppLogo>('APP_LOGO', {
    factory: () => defaultConfig,
});

export const provideAppLogo = (config: Partial<AppLogo>) =>
    makeEnvironmentProviders([
        {
            provide: APP_LOGO,
            useValue: { ...defaultConfig, ...config },
        },
    ]);
