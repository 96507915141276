import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filterMap } from '@interacta-shared/util';
import { filter } from 'rxjs/operators';

/**
 * RoutingHistoryService behaviour not fully consistenct with History API (windows.history, aka browser's back and forward buttons).
 *
 * If you want to prevent a specific page from re-opening by back navigation (es. because it's a creation/edit form) please consider to use replaceUrl=true
 * in all navigation action starting from that page.
 *
 */

@Injectable({ providedIn: 'root' })
export class HistoryRoutingService {
    private history: string[] = [];
    private backDefault = '';

    constructor(
        private router: Router,
        private location: Location,
    ) {}

    loadRouting(): void {
        this.router.events
            .pipe(
                filterMap((event) =>
                    event instanceof NavigationEnd ? event : undefined,
                ),
                // Consistent with History API behaviour: do not include in history entries with skipLocationChange = true
                filter(
                    () =>
                        !this.router.getCurrentNavigation()?.extras
                            ?.skipLocationChange,
                ),
            )
            .subscribe(({ urlAfterRedirects }) => {
                const isReplaceUrl =
                    this.router.getCurrentNavigation()?.extras?.replaceUrl;
                const isSameUrl = this.getCurrentUrl() === urlAfterRedirects;

                if (isReplaceUrl || isSameUrl) {
                    this.history[Math.max(this.history.length - 1, 0)] =
                        urlAfterRedirects;
                    // Remove trailing path from history if it duplicates previuos one
                    // (handle single "back" event)
                    if (this.history.length > 1) {
                        const last = this.history[this.history.length - 1];
                        const secondLast =
                            this.history[this.history.length - 2];
                        if (last === secondLast) {
                            this.history.pop();
                        }
                    }
                } else {
                    this.history.push(urlAfterRedirects);
                }
            });
    }

    back(options: { default: unknown[] } = { default: [this.backDefault] }) {
        if (this.history.length > 1) {
            this.location.back();
        } else {
            this.router.navigate(options.default);
        }
    }

    /**
     * Warning: You probably just need to use { skipLocationChange: true } when
     * navigating to your current route.
     */
    popPreviousUrl(): string {
        this.history.pop();
        return this.getCurrentUrl();
    }

    getCurrentUrl(): string {
        return this.history.length === 0
            ? ''
            : this.history[this.history.length - 1];
    }

    setBackDefault(backDefault: string): void {
        this.backDefault = backDefault;
    }

    navigateTo(path: string): void {
        const currentIndex = this.history.lastIndexOf(this.getCurrentUrl());
        const targetIndex = this.history.lastIndexOf(path);

        if (this.history.includes(path)) {
            const stepsBack = currentIndex - targetIndex;
            const removeItems = stepsBack - 1;
            if (removeItems > 0) {
                // Remove last removeItems from history.
                // Single "back" event is handledd above in loadRouting method
                this.history.splice(
                    this.history.length - removeItems,
                    removeItems,
                );
            }
            this.location.historyGo(-stepsBack);
        } else {
            this.router.navigate([path]);
        }
    }
}
