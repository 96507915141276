import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDisplayService } from '@interacta-shared/data-access-error';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

const errorStatus = 403;

@Injectable()
export class InactiveUserInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private tipService: ErrorDisplayService,
    ) {}

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status === errorStatus &&
                    error.url?.includes('/api/')
                ) {
                    return this.authService.fetchCurrentUserData().pipe(
                        concatMap((u) => {
                            const currUser = u;
                            if (currUser?.blocked) {
                                this.tipService.error(
                                    'SHARED.ERROR.BLOCKED_USER',
                                );
                                return this.authService
                                    .signOut()
                                    .pipe(concatMap(() => EMPTY));
                            } else if (currUser?.deleted) {
                                this.tipService.error(
                                    'SHARED.ERROR.DELETED_USER',
                                );
                                return this.authService
                                    .signOut()
                                    .pipe(concatMap(() => EMPTY));
                            } else {
                                return throwError(error);
                            }
                        }),
                    );
                } else {
                    return throwError(error);
                }
            }),
        );
    }
}
