import { NavigationExtras, Router } from '@angular/router';

export abstract class AuthRoutingService {
    constructor(
        protected config: {
            loginUrl: string;
            loginTermsUrl: string;
            verifyPrivateEmailUrl: string;
            expiredSessionUrl: string;
            externalAuthRedirectUrl: string;
        },
        protected router: Router,
    ) {}

    abstract isLoginPage(url: string): boolean;
    abstract isTermsPage(url: string): boolean;
    abstract isPrivateEmailPage(url: string): boolean;
    abstract isExpiredSessionPage(url: string): boolean;
    abstract isExternalAuthRedirectPage(url: string): boolean;

    navigateLoginPage(extras?: NavigationExtras | undefined): Promise<boolean> {
        return this.router.navigateByUrl(this.config.loginUrl, extras);
    }

    navigateTermsPage(): Promise<boolean> {
        return this.router.navigateByUrl(this.config.loginTermsUrl, {
            replaceUrl: true,
        });
    }
    navigatePrivateEmailPage(): Promise<boolean> {
        return this.router.navigateByUrl(this.config.verifyPrivateEmailUrl, {
            replaceUrl: true,
        });
    }

    navigateExpiredSessionPage(): Promise<boolean> {
        return this.router.navigateByUrl(this.config.expiredSessionUrl);
    }
}
