import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

const noImageSrc = 'assets/shared/images/no_image.jpg';

@Component({
    selector: 'interacta-image',
    templateUrl: './image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass],
})
export class ImageComponent implements OnChanges {
    @Input({ required: true }) src!: string;
    @Input({ required: true }) alt!: string;
    @Input() classes = '';
    @Input() loading: 'lazy' | 'eager' = 'lazy';
    @Input() fallback?: string;

    @Output() imgLoad = new EventEmitter<HTMLImageElement>();
    @Output() loadError = new EventEmitter<Event>();

    _src!: string;
    _fallback!: string;
    _noImageSrc = noImageSrc;

    loaded = false;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['src'] || changes['fallback']) {
            this._src = this.src ?? '';
            this._fallback = this.fallback ?? this._noImageSrc;
        }
    }

    onLoad(element: HTMLImageElement): void {
        this.loaded = true;
        this.imgLoad.next(element);
    }

    onError($event: Event): void {
        this.useFallback();
        this.loadError.emit($event);
    }

    private useFallback(): void {
        this._src =
            this._src !== this._fallback ? this._fallback : this._noImageSrc;
        this.cdr.markForCheck();
    }
}
