/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { toCurrentEnvironment } from '../models/current-environment/current-environment.deserialize';
import { CurrentEnvironment } from '../models/current-environment/current-environment.model';
import {
    toEnvironmentInfo,
    toFrontendBuildInfo,
} from '../models/environment-info/environment-info.deserialize';
import {
    BuildInfo,
    EnvironmentInfo,
} from '../models/environment-info/environment-info.model';
import { ENVIRONMENT } from '../providers/environment.provider';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentInfoService {
    private readonly baseUrlEnvironment = `${inject(ENVIRONMENT).apiBasePath.common}/core/environment-info`;

    constructor(private http: HttpClient) {}

    getCurrentEnvironment(): Observable<CurrentEnvironment> {
        return this.http
            .get<any>(`${this.baseUrlEnvironment}/current-environment`)
            .pipe(map((result) => toCurrentEnvironment(result)));
    }

    getFrontendBuildInfo(): Observable<BuildInfo> {
        return this.http
            .get<any>('./assets/build-info.json')
            .pipe(map((result) => toFrontendBuildInfo(result)));
    }

    getEnvironmentInfo(): Observable<EnvironmentInfo> {
        return forkJoin([
            this.getCurrentEnvironment(),
            this.getFrontendBuildInfo(),
        ]).pipe(
            map(([currentEnvironment, frontendBuildInfo]) =>
                toEnvironmentInfo(currentEnvironment, frontendBuildInfo),
            ),
        );
    }
}
