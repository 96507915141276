import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';

export const canActivateAuthGuard: CanActivateFn = (
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    return inject(AuthGuardService).authenticated(state.url);
};
