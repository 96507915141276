import {
    toLanguage,
    toTimezone,
} from '@interacta-shared/data-access-configuration';
import {
    toArea,
    toBusinessUnit,
} from '../organization/organization.deserialize';
import { AuthUser, CurrentUser, UserSettings } from './auth-user.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toUserSettings = (record: any): UserSettings => ({
    viewUserProfiles: record.viewUserProfiles,
    reducedProfile: record.reducedProfile,
    peopleSectionEnabled: record.peopleSectionEnabled,
    editPrivateEmailEnabled: record.editPrivateEmailEnabled,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toAuthUser = (record: any): AuthUser => ({
    id: record.id,
    fullName: `${record.firstName} ${record.lastName}`,
    lastName: record.lastName,
    firstName: record.firstName,
    contactEmail: record.contactEmail,
    googleAccountId: record.googleAccountId,
    accountPhotoUrl: record.accountPhotoUrl,
    deleted: record.deleted,
    blocked: record.blocked,
    privateProfile: record.privateProfile,
    caption: record.caption,
    licences: record.licences || [],
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toCurrentUser = (record: any): CurrentUser => {
    return {
        ...toAuthUser(record.userData),
        hasCustomCredentials: record.hasCustomCredentials,
        hasGoogleCredentials: record.hasGoogleCredentials,
        hasMicrosoftCredentials: record.hasMicrosoftCredentials,
        canManageCustomCredentials: record.canManageCustomCredentials,
        timezone: record.userPreferences
            ? toTimezone(record.userPreferences.timezone)
            : undefined,
        language: record.userPreferences
            ? toLanguage(record.userPreferences.language)
            : undefined,
        termsAcceptTimestamp: record.userPreferences?.termsAcceptTimestamp
            ? new Date(record.userPreferences.termsAcceptTimestamp)
            : undefined,
        area: record.userInfo?.area ? toArea(record.userInfo.area) : undefined,
        businessUnit: record.userInfo?.businessUnit
            ? toBusinessUnit(record.userInfo.businessUnit)
            : undefined,
        occToken: record.occToken,
        privateEmail: record.userInfo?.privateEmail ?? null,
        privateEmailVerified: record.userInfo?.privateEmailVerified ?? false,
        privateEmailVerificationRequired:
            record.userInfo?.privateEmailVerificationRequired ?? false,
        emailNotificationsEnabled:
            record.userPreferences?.emailNotificationsEnabled ?? true,
        userSettings: record.userSettings
            ? toUserSettings(record.userSettings)
            : undefined,
    };
};
