import { inject, Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { login, logout } from './auth.actions';

@Injectable()
export class AuthEffects {
    private authService = inject(AuthService);

    sendLoginAction$ = createEffect(() =>
        this.authService.login$.pipe(map(() => login())),
    );

    sendLogoutAction$ = createEffect(() =>
        this.authService.logout$.pipe(map(() => logout())),
    );
}
