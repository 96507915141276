import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconPreloaderService } from './icon-preloader.service';
import { InteractaIcon } from './icon-registry.model';

@Component({
    selector: 'interacta-icon',
    standalone: true,
    imports: [MatIconModule],
    template: `
        <mat-icon style="display: block" [inline]="true" [svgIcon]="icon" />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
    @Input({ required: true }) icon!: InteractaIcon;

    private readonly _preloader = inject(IconPreloaderService);
}
