import { Timezone } from './date-time/date-time.model';
import { Language } from './language/language.model';

export interface AppSettings {
    language: Language;
    systemLanguage?: Language;
    timezone: Timezone;
    readonly canUserManageCustomCredentials: boolean;
    readonly autogeneratePassword: boolean;
    readonly resetPasswordNextAccess: boolean;
    readonly attachmentMaxSize: number;
    readonly homePageRefreshRate: number;
}

export const defaultSettings: AppSettings = {
    attachmentMaxSize: 10485760,
    homePageRefreshRate: 30 * 60 * 1000,
    language: { code: 'it', description: 'italiano' },
    timezone: {
        id: 276,
        zoneId: 'Europe/Paris',
        description: 'Europe/Paris',
    },
    canUserManageCustomCredentials: false,
    autogeneratePassword: false,
    resetPasswordNextAccess: false,
};
